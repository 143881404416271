import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import manuel_ from "../../../static/img/equipo/manuel_.png"
import antonio_ from "../../../static/img/equipo/antonio_.png"
import benjamin_ from "../../../static/img/equipo/benjamin_.png"
import neli_ from "../../../static/img/equipo/neli_.png"
import miguel_ from "../../../static/img/equipo/miguel_.png"
import ricardo from "../../../static/img/equipo/ricardo.png"
import jorge1 from "../../../static/img/equipo/jorge1.png"
import selene1 from "../../../static/img/equipo/selene1.png"
import abigail from "../../../static/img/equipo/abigail.png"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Team"/>
            <Banner title="Team"/>
            <section className="executive_area equipo">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-5">
                            <div className="offer_item">
                                <div className="offer_content">
                                    <div className="inner_flip">
                                        <img className="w-100 wow fadeIn" src={manuel_}
                                             alt="Manuel S. Pérez"/>
                                        <h3>Manuel S. Perez</h3>
                                        <p>Managing Director</p>
                                        <div className="social_icon">
                                            <a target={'_blank'}
                                               href="https://www.linkedin.com/in/manuelspereza/"><i
                                                className="fa fa-linkedin"></i></a>
                                            {/*<a target={'_blank'}*/}
                                               {/*href="https://twitter.com/irstrat"><i*/}
                                                {/*className="fa fa-twitter"></i></a>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="offer_item">
                                <div className="offer_content">
                                    <div className="inner_flip">
                                        <img className="w-100 wow fadeIn" src={antonio_} alt="J.A. Lovera"/>
                                        <h3>J.A. Lovera</h3>
                                        <p>Managing Director of Operations</p>
                                        <div className="social_icon">
                                            <a target={'_blank'}
                                               href="https://www.linkedin.com/in/antonio-lovera-09440433/"><i
                                                className="fa fa-linkedin"></i></a>
                                            {/*<a target={'_blank'}*/}
                                               {/*href="https://twitter.com/rubymatico"><i*/}
                                                {/*className="fa fa-twitter"></i></a>*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="offer_item">
                                <div className="offer_content">
                                    <div className="inner_flip">
                                        <img className="w-100 wow fadeIn" src={benjamin_}
                                             alt="Benjamín Salgado"/>
                                        <h3>Benjamin Salgado</h3>
                                        <p>Intelligence and Backoffice Manager</p>
                                        <div className="social_icon">
                                            <a target={'_blank'} href="https://www.linkedin.com/in/benjam%C3%ADn-salgado-69b1b8b6/"><i className="fa fa-linkedin"></i></a>
                                            {/*<a target={'_blank'} href="#"><i className="fa fa-twitter"></i></a>*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="offer_item">
                                <div className="offer_content">
                                    <div className="inner_flip">
                                        <img className="w-100 wow fadeIn" src={neli_}
                                             alt="Nelly Rangel"/>
                                        <h3>Nelibeth Rangel</h3>
                                        <p>Associate in Corporate Communications</p>
                                        <div className="social_icon">
                                            <a target={'_blank'} href="https://www.linkedin.com/in/nelibethrangel/"><i className="fa fa-linkedin"></i></a>
                                            {/*<a target={'_blank'} href="#"><i className="fa fa-twitter"></i></a>*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="offer_item">
                                <div className="offer_content">
                                    <div className="inner_flip">
                                        <img className="w-100 wow fadeIn" src={miguel_}
                                             alt="Miguel Bermejo"/>
                                        <h3>Miguel Bermejo</h3>
                                        <p>Operations Manager</p>
                                        <div className="social_icon">
                                            <a target={'_blank'} href="https://www.linkedin.com/in/miguel-gonzalo-bermejo-rocher-2982a1136/"><i className="fa fa-linkedin"></i></a>
                                            {/*<a target={'_blank'} href="#"><i className="fa fa-twitter"></i></a>*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="offer_item">
                                <div className="offer_content">
                                    <div className="inner_flip">
                                        <img className="w-100 wow fadeIn" src={ricardo}
                                             alt="Ricardo Avila"/>
                                        <h3>Ricardo Avila</h3>
                                        <p>Assistant Operations Manager</p>
                                        <div className="social_icon">
                                            <a target={'_blank'} href="https://www.linkedin.com/in/ricardo-eugenio-avila-novelo-75059b182/"><i className="fa fa-linkedin"></i></a>
                                            {/*<a target={'_blank'} href="#"><i className="fa fa-twitter"></i></a>*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="offer_item">
                                <div className="offer_content">
                                    <div className="inner_flip">
                                        <img className="w-100 wow fadeIn" src={jorge1}
                                             alt="Jorge Pacheco"/>
                                        <h3>Jorge Pacheco </h3>
                                        <p>Assistant Design Manager</p>
                                        <div className="social_icon">
                                            <a target={'_blank'} href="http://linkedin.com/in/jorge-e-pacheco"><i className="fa fa-linkedin"></i></a>
                                            {/*<a target={'_blank'} href="#"><i className="fa fa-twitter"></i></a>*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="offer_item">
                                <div className="offer_content">
                                    <div className="inner_flip">
                                        <img className="w-100 wow fadeIn" src={selene1}
                                             alt="Abigail Ciau"/>
                                        <h3>Abigail Ciau </h3>
                                        <p>Associate in Investor Relations</p>
                                        <div className="social_icon">
                                            <a target={'_blank'} href="https://www.linkedin.com/in/selene-abigail-ciau-puga-0301391b7/"><i className="fa fa-linkedin"></i></a>
                                            {/*<a target={'_blank'} href="#"><i className="fa fa-twitter"></i></a>*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
